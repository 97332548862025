'use strict';

const body = $('body');
const wind = $(window);
const bodyHTML = $('body, html');

/* ======================================
Header
====================================== */
let scrollPosi;

function bodyFix() {
	scrollPosi = $(window).scrollTop();

	body.addClass('is-fixed').css({ top: -scrollPosi });
}

function bodyFixReset() {
	body.removeClass('is-fixed').css({ top: '0' });

	bodyHTML.scrollTop(scrollPosi);
}

(() => {
	const btn = $('.js-menu');
	const btnClose = $('.js-close-menu');
	const content = $('.c-header__content');
	const headerScroll = $('.js-header-scroll');

	wind.on('load resize', function () {
		setTimeout(
			() =>
				wind.outerWidth() < 768 && content.css('transition', '.3s ease-in-out'),
			500,
		);

		wind.outerWidth() > 767 && content.removeAttr('style');
	});

	if (btn.length) {
		btn.on('click', function () {
			const header = $(this).parents('.c-header');
			const header_content = header.find('.c-header__content');

			$(this).toggleClass('is-active');
			header_content.toggleClass('is-active');
			header.toggleClass('is-menu-open');

			if (header_content.hasClass('is-active')) {
				bodyFix();
			} else {
				bodyFixReset();
			}

			if (wind.innerHeight() < $('.c-header__content__inner').innerHeight()) {
				header_content.removeClass('d-flex');
			}
		});

		$(window).bind('pageshow', function (event) {
			if (event.originalEvent.persisted) {
				$('.c-header__content').addClass('is-hide');
				resetMenuHeader();

				setTimeout(() => {
					$('.c-header__content').removeClass('is-hide');
				}, 0);
			}
		});
	}

	wind.on('resize', () => {
		if (wind.innerHeight() < $('.c-header__content__inner').innerHeight()) {
			$('.c-header__content').removeClass('d-flex');
		} else {
			$('.c-header__content').addClass('d-flex');
		}
	});

	if (btnClose.length) {
		btnClose.on('click', function () {
			resetMenuHeader();
		});
	}

	wind.on('scroll load', () => {
		headerScroll.length && wind.scrollTop() > 0
			? headerScroll.addClass('is-scrolling')
			: headerScroll.removeClass('is-scrolling');
	});
})();

function resetMenuHeader() {
	$('.js-menu').removeClass('is-active');
	$('.c-header__content').removeClass('is-active');
	$('.c-header').removeClass('is-menu-open');
	bodyFixReset();
}

/* ======================================
MV1 slider
====================================== */
function sliderMvTop() {
	const swiper = new Swiper('.js-mv1-slide1', {
		observer: true,
		observeParents: true,
		direction: 'vertical',
		loop: true,
		loopedSlides: 999,
		speed: 700,
		autoplay: {
			delay: 4700,
		},
		slidesPerView: 4,
		slidesPerGroup: 2,
		spaceBetween: 0,
		allowTouchMove: false,
		breakpoints: {
			768: {
				centeredSlides: true,
				slidesPerView: 3,
				spaceBetween: 0,
				slidesPerGroup: 1,
			},

			1145: {
				centeredSlides: true,
				slidesPerView: 3,
				spaceBetween: 0,
				slidesPerGroup: 1,
			},
		},
		on: {
			init: function () {
				if (wind.outerWidth() > 767) {
					$('.swiper-slide-active img').css('opacity', 1);
				} else {
					$('.swiper-slide').removeClass('pos-sp pos-sps');
					$('.swiper-slide-next img').css('opacity', 1);
					$('.swiper-slide-next').next().find('img').css('opacity', 1);
					$('.swiper-slide-next').next().addClass('pos-sp');
					$('.swiper-slide.pos-sp').next().addClass('pos-sps');
				}

				setTimeout(() => {
					if (wind.outerWidth() > 767) {
						$('.swiper-slide-active img').css('opacity', 0);
					} else {
						$('.swiper-slide-next img').css('opacity', 0);
						$('.swiper-slide-next').next().find('img').css('opacity', 0);
					}
				}, 3000);

				setTimeout(() => {
					$('.swiper-slide').addClass('top-transition');
				}, 100);
			},

			slideChange: function () {
				setTimeout(() => {
					if (wind.outerWidth() > 767) {
						$(
							'.swiper-slide-next img, .swiper-slide-prev img, .swiper-slide-duplicate img',
						).css('opacity', 0);
						$('.swiper-slide-active img').css('opacity', 1);
					} else {
						$('.swiper-slide').removeClass('pos-sp pos-sps');
						$('.swiper-slide img').css('opacity', 0);
						$('.swiper-slide-next img').css('opacity', 1);
						$('.swiper-slide-next').next().find('img').css('opacity', 1);
						$('.swiper-slide-next').next().addClass('pos-sp');
						$('.swiper-slide.pos-sp').next().addClass('pos-sps');
					}
				}, 50);

				setTimeout(() => {
					if (wind.outerWidth() > 767) {
						$('.swiper-slide-active img').css('opacity', 0);
					} else {
						$('.swiper-slide-next img').css('opacity', 0);
						$('.swiper-slide-next').next().find('img').css('opacity', 0);
					}
				}, 3800);
			},
		},
	});
}

$(() => {
	let listSwiper = [];
	let listTimeout = [];

	let initSwiper = function () {
		listSwiper = [];
		let _showImage = function (swiper) {
			let activeList = [];
			let active = $('.swiper-slide-active', swiper.$el);

			if (wind.outerWidth() <= 767) {
				let nextElem1 = active.next();
				if (nextElem1) {
					activeList.push(nextElem1);
				}

				let nextElem2 = nextElem1.next();
				if (nextElem2) {
					activeList.push(nextElem2);
				}
			} else {
				activeList.push(active);
			}

			for (let i = 0; i < activeList.length; i++) {
				if (activeList[i] && activeList[i].length) {
					let activeElem = activeList[i];
					let activeHide = function () {
						$('img', activeElem).css('opacity', 0);
					};
					$('img', activeElem).css('opacity', 1);
					listTimeout.push(setTimeout(activeHide, 2300));
				}
			}
		};

		$('.js-mv1-slide1').each(function () {
			let _this = this;
			let _swiper = new Swiper(_this, {
				observer: true,
				observeParents: true,
				direction: 'vertical',
				loop: true,
				speed: 700,
				slidesPerView: 4,
				slidesPerGroup: 2,
				spaceBetween: 0,
				allowTouchMove: false,
				centeredSlides: false,
				breakpoints: {
					768: {
						centeredSlides: true,
						slidesPerView: 3,
						slidesPerGroup: 1,
						spaceBetween: 0,
					},

					1145: {
						centeredSlides: true,
						slidesPerView: 3,
						slidesPerGroup: 1,
						spaceBetween: 0,
					},
				},
				on: {
					init: function (swiper) {
						_showImage(swiper);
					},

					slideChangeTransitionStart: function (swiper) {
						_showImage(swiper);
					},
				},
			});
			listSwiper.push(_swiper);
		});
	};

	let loopSwiper = function () {
		listTimeout.push(
			setTimeout(function () {
				for (let i = 0; i < listSwiper.length; i++) {
					listSwiper[i].slideNext();
				}
				loopSwiper();
			}, 4000),
		);
	};

	initSwiper();
	loopSwiper();

	let reinitSwiper = function () {
		for (let i = 0; i < listSwiper.length; i++) {
			$('.swiper-slide img', listSwiper[i].$wrapperEl).css('opacity', 0);
			listSwiper[i].destroy();
		}
		for (let i = 0; i < listTimeout.length; i++) {
			clearTimeout(listTimeout[i]);
		}
		initSwiper();
		loopSwiper();
	};

	let widthSwiper = wind.outerWidth();
	$(window).on('resize', function () {
		if (
			(wind.outerWidth() <= 767 && widthSwiper > 767) ||
			(wind.outerWidth() > 767 && widthSwiper <= 767)
		) {
			reinitSwiper();
		}
		widthSwiper = wind.outerWidth();
	});

	// sliderMvTop();
	// window.requestAnimationFrame(sliderMvTop);
});

/* ======================================
Popup modal and slider
====================================== */
(() => {
	const btnOpen = $('.js-open-modal');
	const btnClose = $('.js-close-modal');
	const modal = $('.c-modal');

	const swiper = new Swiper('.js-modal-slide', {
		speed: 300,

		navigation: {
			nextEl: '.js-modal-next',
			prevEl: '.js-modal-prev',
		},

		breakpoints: {
			767: {
				speed: 500,
			},
		},
	});

	showNextPrevBtn();

	if (btnOpen.length) {
		btnOpen.on('click', function () {
			let idxSlide = $(this).data('modal-index');

			swiper.slideTo(idxSlide, 0);

			modal.addClass('is-show');
			$('.js-header-scroll').addClass('is-open-modal');
			bodyFix();
		});
	}

	swiper.on('slideChange', function () {
		showNextPrevBtn();
	});

	swiper.on('slideChangeTransitionStart', function (e) {
		$('.js-modal-next span').text($('.swiper-slide-next .c-card__tag').text());
		$('.js-modal-prev span').text($('.swiper-slide-prev .c-card__tag').text());
	});

	if (btnClose.length) {
		btnClose.on('click', () => {
			$('.js-header-scroll').removeClass('is-open-modal');
			modal.removeClass('is-show');
			bodyFixReset();
		});
	}

	wind.on('click', (e) => {
		if ($(e.target).is('.c-modal')) {
			btnClose.trigger('click');
		}
	});

	function showNextPrevBtn() {
		if (swiper.isBeginning) {
			$('.js-modal-next').removeClass('is-hide');
			$('.js-modal-prev').addClass('is-hide');
		}

		if (swiper.isEnd) {
			$('.js-modal-prev').removeClass('is-hide');
			$('.js-modal-next').addClass('is-hide');
		}

		if (!swiper.isEnd && !swiper.isBeginning) {
			$('.js-modal-next, .js-modal-prev').removeClass('is-hide');
		}
	}

	wind.on('load resize', () => {
		const modalBoxHeight = $('.c-modal__box').innerHeight();

		if (wind.innerHeight() > modalBoxHeight) {
			$('.c-modal').addClass('is-items-center');
		} else {
			$('.c-modal').removeClass('is-items-center');
		}
	});
})();

/* ======================================
FAQ
====================================== */
(() => {
	const faq = $('.js-faq');

	if (faq.length) {
		faq.find('.c-faq__q').on('click', function () {
			$(this).parents('.js-faq').toggleClass('is-active');
			$(this).next('.c-faq__a').slideToggle();
		});
	}
})();

/* ======================================
Match height
====================================== */
wind.on('load resize', () => {
	$('.js-list3-icon, .js-matchheight')?.matchHeight();
});

/* ======================================
Select years, months, dates
====================================== */
(() => {
	const selectYear = document.querySelectorAll('.js-get-years');
	const selectMonth = document.querySelectorAll('.js-get-months');
	const selectDate = document.querySelectorAll('.js-get-dates');
	const htmlOp = `<option value="" selected disabled>選択してください</option>`;

	wind.on('load resize', () => {
		if (wind.outerWidth() < 768) {
			const op = $('option[disabled]');
			op.length && op.remove();
		} else {
			$('.js-get-years, .js-get-months, .js-get-dates').html(htmlOp);
		}

		if (selectYear.length) {
			const min = 1950;
			const now = new Date().getFullYear();

			for (let i = min; i <= now; i++) {
				const opt = document.createElement('option');
				opt.value = i;
				opt.innerHTML = i;
				selectYear[0].appendChild(opt);
			}
		}

		if (selectMonth.length) {
			for (let i = 1; i <= 12; i++) {
				const opt = document.createElement('option');
				opt.value = i;
				opt.innerHTML = i;
				selectMonth[0].appendChild(opt);
			}
		}

		if (selectDate.length) {
			for (let i = 1; i <= 31; i++) {
				const opt = document.createElement('option');
				opt.value = i;
				opt.innerHTML = i;
				selectDate[0].appendChild(opt);
			}
		}
	});
})();
/* ======================================
Achor link
====================================== */
$(document).on('click', 'a[href*="#"]', function (e) {
	let hash = this.hash;
	let target = $(hash === '#' || hash === '' ? 'html' : hash);
	if (target.length === 0) {
		target = $('[data-anchor="' + hash.slice(1) + '"]');
	}
	let locationPath = window.location.pathname.replace(/\//g, '');
	let thisPath = this.pathname.replace(/\//g, '');
	if (target.length && locationPath === thisPath) {
		e.preventDefault();
		let headerHeight = $('header').innerHeight();
		let targetPosition = target.offset().top;
		let scrollStop = function () {
			$('body, html').stop(true);
		};
		$('body, html').animate(
			{ scrollTop: targetPosition - headerHeight },
			800,
			'swing',
		);
		window.addEventListener('DOMMouseScroll', scrollStop, false);
		window.onmousewheel = document.onmousewheel = scrollStop;
	}
});

let clsSelect = $('.c-select__box select');
let clsSelectOption0 = $('.c-select__box select option[value=""]');
let txtOption0 = '選択してください';
clsSelectOption0.html(txtOption0);

if ($('.error').length) {
	let headerHeight = $('header').innerHeight();
	let targetPosition = $('.p-career5 .c-title3').offset().top;
	$('body, html').animate(
		{ scrollTop: targetPosition - headerHeight },
		800,
		'swing',
	);
}
